export enum EHttpStatus {
  BadRequest = 400,
  Unauthorized = 401,
  NotPaid = 402,
  Forbidden = 403,
  NotFound = 404,
  Conflict = 409,
  Gone = 410,
  FailedDependency = 424,
  InternalServerError = 500,
  BadGateway = 502,
  ServiceUnavailable = 503,
  GatewayTimeout = 504,
}

export enum EApiRequestError {
  NetworkError = "networkError",
  BadRequest = "badRequest",
  Unauthorized = "unauthorized",
  Forbidden = "forbidden",
  NotFound = "notFound",
  Revoked = "revoked",
  InternalServerError = "internalServerError",
  ServiceUnavailable = "serviceUnavailable",
  GatewayTimeout = "gatewayTimeout",
  FailedDependency = "failedDependency",
  Unknown = "unknown",
}

export enum EApiRequestMessage {
  INVALID_VOUCHER_CODE = "formErrors.voucher.invalidCode",
  VOUCHER_EXPIRED = "formErrors.voucher.voucherExpired",
  VOUCHER_USED = "formErrors.voucher.voucherNotActive",
  TRANSACTION_ALREADY_DISCOUNTED = "formErrors.voucher.transactionAlreadyDiscounted",
  VOUCHER_NOT_APPLICABLE_FOR_GIVEN_PRODUCT = "formErrors.voucher.voucherNotApplicableForGivenProduct",
  INVALID_CREDENTIALS = "formErrors.login.invalidCredentials",
  USER_BLOCKED = "formErrors.login.lockedAccount",
  INVALID_VIN = "vinSearch.errors.invalidVin.headline",
  INVALID_LP = "vinSearch.errors.invalidLp.headline",
  VIES_CHECK_ERROR = "error.viesCheck.errorMessage",
}

const statusMap = new Map();
statusMap.set(EHttpStatus.BadRequest, EApiRequestError.BadRequest);
statusMap.set(EHttpStatus.Unauthorized, EApiRequestError.Unauthorized);
statusMap.set(EHttpStatus.Forbidden, EApiRequestError.Forbidden);
statusMap.set(EHttpStatus.NotFound, EApiRequestError.NotFound);
statusMap.set(EHttpStatus.Gone, EApiRequestError.Revoked);
statusMap.set(EHttpStatus.InternalServerError, EApiRequestError.InternalServerError);
statusMap.set(EHttpStatus.ServiceUnavailable, EApiRequestError.ServiceUnavailable);
statusMap.set(EHttpStatus.GatewayTimeout, EApiRequestError.GatewayTimeout);
statusMap.set(EHttpStatus.FailedDependency, EApiRequestError.FailedDependency);

const mapToApiRequestError = (status: EHttpStatus): EApiRequestError => statusMap.get(status);

const mapResponseMessageToErrorMessage = (message: string): string => EApiRequestMessage[message];

export const httpStatusMapper = {
  mapToApiRequestError,
  mapResponseMessageToErrorMessage,
};
